import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

import { getImageStyles } from '../utils/utils';
import style from '../pages/index.module.css';

const ContactImg = () => {
  const { contentfulContact } = useStaticQuery(graphql`
    {
      contentfulContact {
        image {
          fixed(height: 1500, quality: 65) {
            ...GatsbyContentfulFixed
            aspectRatio
          }
        }
      }
    }
  `);

  const imgStyles = getImageStyles(contentfulContact.image.fixed.aspectRatio);

  return (
    <div className={style.container}>
      <Img
        style={imgStyles}
        imgStyle={imgStyles}
        fixed={contentfulContact.image.fixed}
        loading="eager"
      />
    </div>
  );
};

export default ContactImg;
