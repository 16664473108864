import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

const ContactText = () => {
  const { contentfulContact } = useStaticQuery(graphql`
    {
      contentfulContact {
        text {
          text
        }
      }
    }
  `);

  return <p>{contentfulContact.text.text}</p>;
};

export default ContactText;
