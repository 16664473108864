import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';
import ContactText from '../components/contact-text';
import ContactImg from '../components/contact-image';

const ContactPage = () => (
  <Layout>
    <SEO title="Contatto" />
    {process.env.GATSBY_SITE_SARA ? <ContactText /> : <ContactImg />}
  </Layout>
);

export default ContactPage;
